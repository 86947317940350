<template>
  <div class="dashboard">
    <el-tabs v-model="activeName" @tab-click="handleTabChange">
      <el-tab-pane label="JETree" name="jeTree">
        <JETree :isForAdmin="false" />
      </el-tab-pane>
      <el-tab-pane label="My Weekly Report" name="myWeeklyReport" :lazy="true">
        <div
          v-if="this.showGeneralTextForWeeklyReport"
          style="padding: 40px; text-align: center; font-size: 1.5rem"
        >
          System cannot find any active weekly report for you.
        </div>
        <el-tabs v-if="showCurrentWeeklyReport" v-model="weeklyReportActiveName">
          <el-tab-pane
            :lazy="true"
            v-for="(item, index) in this.weeklyReportList"
            :key="index"
            :label="this.$formatter.formatGrade(item.grade) + ' ' + item.term"
            :name="'weeklyReportChart_' + index"
          >
            <table class="dtable">
              <tr>
                <td>
                  <h2 class="subject">
                    Weekly Report of My Homework for
                    {{ this.$formatter.formatGrade(item.grade) }},
                    {{ item.term }}
                  </h2>
                  <weekly-report-chart
                    :isForAdmin="false"
                    recordType="homework"
                    :reportId="item.reportId"
                    :studentId="query.studentId"
                    :destroy-on-close="true"
                  />
                  <h2 class="subject">
                    Weekly Report of My Quiz for
                    {{ this.$formatter.formatGrade(item.grade) }},
                    {{ item.term }}
                  </h2>
                  <weekly-report-chart
                    :isForAdmin="false"
                    recordType="quiz"
                    :reportId="item.reportId"
                    :studentId="query.studentId"
                    :destroy-on-close="true"
                  />
                </td>
              </tr>
            </table>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>
      <el-tab-pane label="My Term Report" name="myReport" :lazy="true">
        <table class="dtable">
          <tr>
            <td><student-test-record-history-chart /></td>
            <td title="click to refresh" class="points" @click="loadStudentPoints(false)">
              My Current Points
              {{
                this.pointsData.points +
                this.pointsData.pendingPoints +
                this.pointsData.creditPoints
              }}
              <el-icon v-if="loadingStudentPoints" class="is-loading">
                <loading />
              </el-icon>
            </td>
          </tr>
          <tr>
            <td>
              <h2 v-if="showCurrentReport" class="subject">
                My Latest Report -
                {{ this.$formatter.formatGrade(this.currentReport.grade) }},
                {{ this.currentReport.term }}
              </h2>
              <test-report-chart
                v-if="showCurrentReport"
                :isForAdmin="false"
                :reportId="currentReport.reportId"
                :studentId="query.studentId"
                :destroy-on-close="true"
              />
            </td>
            <td></td>
          </tr>
        </table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { getData } from "../../service/api";
import StudentTestRecordHistoryChart from "../common/StudentTestRecordHistoryChart.vue";
import TestReportChart from "../common/TestReportChart.vue";
import WeeklyReportChart from "../common/WeeklyReportChart.vue";
import JETree from "../common/JETree.vue";

export default {
  components: {
    StudentTestRecordHistoryChart,
    TestReportChart,
    WeeklyReportChart,
    JETree,
  },

  name: "dashboard",
  data() {
    return {
      query: {
        studentId: null,
      },
      activeName: "jeTree",
      showGeneralTextForWeeklyReport: false,
      showCurrentReport: false,
      loadingStudentPoints: false,
      currentReport: {},
      weeklyReportActiveName: "weeklyReportChart_0",
      weeklyReportList: [],
    };
  },
  created() {
    let studentId = this.$user.getUsername();
    if (studentId) {
      this.query.studentId = studentId;
      this.initAndLoadData();
    }
  },
  computed: {
    pointsData() {
      return this.$store.state.pointsData;
    },
  },
  methods: {
    initAndLoadData() {
      if (this.activeName === "jeTree") {
        this.$store.commit("handleShowGlobalFloater", true);
      } else {
        this.$store.commit("handleShowGlobalFloater", false);
      }
      this.loadStudentPoints();
      this.getStudentCurrentReport();
    },
    loadStudentPoints() {
      this.$user.refreshPoints();
    },
    getStudentCurrentReport() {
      getData(`testReport/getCurrentTestReportForStudent`, null, {
        isShowLoading: false,
      }).then((res) => {
        if (res.result && res.code === "200") {
          this.currentReport = res.result;
          this.showCurrentReport = true;
        }
      });
      getData(`weeklyReport/getCurrentStudentActiveWeeklyReports`, null, {
        isShowLoading: false,
      }).then((res) => {
        if (res.code === "200" && res.result && res.result.length) {
          this.showGeneralTextForWeeklyReport = false;
          this.weeklyReportList = res.result;
          this.showCurrentWeeklyReport = true;
          this.weeklyReportActiveName = `weeklyReportChart_${
            this.weeklyReportList.length - 1
          }`;
        } else {
          this.showCurrentWeeklyReport = true;
          this.showGeneralTextForWeeklyReport = true;
        }
      });
    },
    handleTabChange(pane) {
      console.log("handleTabChange", pane);
      if (pane && pane.paneName == "jeTree") {
        this.$store.commit("handleShowGlobalFloater", true);
      } else {
        this.$store.commit("handleShowGlobalFloater", false);
      }
    },
  },
};
</script>
<style scoped>
.dashboard .subject {
  height: 38px;
  line-height: 38px;
  color: #3f9dff;
  text-align: center;
  border-bottom: solid 2px #e4e7ed;
  font-weight: 400;
}
.dashboard .dtable {
  display: block;
  min-width: 100%;
  height: 100%;
  word-wrap: break-word;
  display: table;
}
.dashboard table.dtable tr {
  min-width: 100%;
}
.dashboard table.dtable tr td {
  width: 50%;
  height: 100%;
  word-wrap: break-word;
}
.dashboard .points {
  font-weight: 600;
  font-size: 3.34rem;
  color: red;
  background-color: #eeeeee;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}
</style>
